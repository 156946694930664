import objectFitImages from 'object-fit-images';
import Macy from 'macy';
const postImages = document.querySelectorAll('img.js-object-fit');
objectFitImages(postImages);
//サイドバー
import './vendar/jquery.nicescroll';


export const mq = window.matchMedia('screen and (max-width:768px)');

//IE判定
const userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
  $('.js-wrapper').addClass('-is-ie');
}

//タブレット判定
if (userAgent.indexOf('ipad') > -1) {
  $('meta[name="viewport"]').attr('content', 'width=1300');
  $('body').addClass('-is-tablet');
}

const header = document.getElementById('js-header');
//サイドバー　黄色ライン　CSSでは高さが取得できないのでJSで操作する

//サイドバー　スクロール
$(function(){
  if (!mq.matches) {
    let headerSidebarHeight = header.scrollHeight;
    yellowLine(headerSidebarHeight);
    $("#js-header").niceScroll(
      {
        cursorcolor: "rgba(255,255,255,0)",
        cursorborder: '1px solid rgba(255,255,255,0)'
      }
    );
    window.addEventListener('resize', () => {
      let headerSidebarHeight = header.scrollHeight;
      yellowLine(headerSidebarHeight);
    });
  }
});

//サイドバースクロール時：黄色のラインが途切れるのを防ぐ
const globalNav = document.getElementById('js-global-nav');
const head = document.querySelector('head');

function yellowLine(height) {
  if (document.getElementById('yellowLine')) {
    document.getElementById('yellowLine').remove();
  }
  const style = document.createElement("style");
  style.setAttribute('id', 'yellowLine');
  style.innerHTML = `#js-header::before { height:${height}px } `;
  head.appendChild(style);
}

//ヘッダー　SP
const wrapper = document.getElementById('js-wrapper');
const globalNavButtons = document.getElementsByClassName('js-global-button');
if(mq.matches) {
  window.addEventListener('load',()=>{
    //ボタンのクリック処理
    const headerHegiht = header.clientHeight;
    wrapper.style.paddingTop = `${headerHegiht}px`;
  });

  //グロナビ展開時、背景固定処理　複雑なのでjQueryでやる
  let scroll2 = 0;
  $('.js-global-button').on('click', function () {
    const navheight = globalNav.scrollHeight;
    grayLine(navheight);
    scroll2 = $(window).scrollTop();

    $('#js-wrapper').css({
      top: `-${scroll2}px`
    });
    // if (scroll2 > $('#js-header').innerHeight() + 100) {
    //   $('#js-header').addClass('-is-fixed');
    // }
    $('#js-wrapper').toggleClass('-is-open');

    //閉じるボタンをクリックした場合 スクロール位置を戻してあげる
    $('.js-global-button--close').on('click', function () {
      $('#js-wrapper').removeClass('-is-open');
      $('body,html').scrollTop(`${scroll2}`);
    });

    $('#js-wrapper').on('touchend', function (event) {
      if (!$('#js-wrapper').hasClass('-is-open')) {
        return;
      }
      if (!$(event.target).closest('.js-global-nav').length) {
        $('#js-wrapper').removeClass('-is-open');
        $('body,html').scrollTop(`${scroll2}`);
      }
    });

  });
}

function grayLine(height) {
  if (document.getElementById('grayLine')) {
    document.getElementById('grayLine').remove();
  }
  const style = document.createElement("style");
  style.setAttribute('id', 'grayLine');
  style.innerHTML = `#js-global-nav::before { height:${height}px } `;
  head.appendChild(style);
}

//バナー一覧
const gridGallery = document.getElementById('js-archive-banner');

if (gridGallery != null) {
  const gallery = Macy({
    container: '#js-archive-banner',
    trueOrder: false,
    waitForImages: false,
    margin:0,
    columns: 4,
    breakAt: {
      1300: {
        columns: 3,
      },
      768: {
        columns: 2,
        margin: 0,
      }
    }
  });
}


//hoverで画像置換
// const hoverChange = document.getElementsByClassName('js-hover-imgChange');
// if (hoverChange.length != null) {
//   Array.from(hoverChange).forEach(item => {
//     item.addEventListener('mouseover',() => {
//       item.classList.add('-is-hover');
//     },false);
//     item.addEventListener('mouseleave',() => {
//       item.classList.remove('-is-hover');
//     },false);
//   });
// }


//header スクロール
// if (mq.matches) {
//   $(window).on('load scroll', function () {
//     let scroll = $(window).scrollTop();
//     if (scroll > $('#js-header').innerHeight() && scroll < $('#js-header').innerHeight() + 100) {
//       $('#js-header').addClass('-not-show');
//     }
//     else if (scroll > $('#js-header').innerHeight() + 100) {
//       $('#js-header').addClass('-is-fixed');
//       $('#js-header').removeClass('-not-show');
//     }
//     else if (!$('.js-wrapper').hasClass('-is-open')) {
//       $('#js-header').removeClass('-is-fixed');
//       $('#js-header').removeClass('-not-show');
//     }
//   });
// }
