import Swiper from 'swiper';
import {mq} from './common'

function relationSwiper() {
  new Swiper('.js-swiper-container--pc', {
    loop: true,
    slidesPerView: 1.15,
    spaceBetween: 20,
    centeredSlides: true,
    navigation: {
      nextEl: '.js-swiper-container--pc ~ .swiper-button-next',
      prevEl: '.js-swiper-container--pc ~ .swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 44
      },
    }
  });
}

const relationSwiper2 = function () {
  new Swiper('.js-swiper-container--sp', {
    loop: true,
    slidesPerView: 1.15,
    spaceBetween: 20,
    centeredSlides: true,
    navigation: {
      nextEl: '.js-swiper-container--sp ~ .swiper-button-next',
      prevEl: '.js-swiper-container--sp ~ .swiper-button-prev',
    }
  });
}

//スライダーを有効にする場合、静的用のclassをはずす
function disabledFlex($target) {
  $(`${$target} .swiper-slide`).each(function () {
    $(this).removeClass('c-single-relation-card');
  });
}


//PC・SPともにslider有効
if ($('.-is-single').length) {
  if (!mq.matches) {
    if ($('.js-swiper-container--pc .swiper-slide:not(.swiper-slide-duplicate)').length > 3) {
      disabledFlex('.js-swiper-container--pc');
      relationSwiper();
    }
    else {
      $('.js-swiper-container--pc ~ .c-slider-controller').remove();
    }
  }
  else {
    if ($('.js-swiper-container--pc .swiper-slide:not(.swiper-slide-duplicate)').length > 2) {
      disabledFlex('.js-swiper-container--pc');
      relationSwiper();
    }
  }

  //SPのみslider有効
  if (!mq.matches) {
    $('.js-swiper-container--sp ~ .c-slider-controller').remove();
    // if (relationSwiper2 !== undefined) {
    //   relationSwiper2.destroy(true, true);
    // }
  } else {
    if ($('.js-swiper-container--sp .swiper-slide:not(.swiper-slide-duplicate)').length > 1) {
      disabledFlex('.js-swiper-container--sp');
      relationSwiper2();
    }
    else {
      $('.js-swiper-container--sp ~ .c-slider-controller').remove();
    }
  }
}



